import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import FileDownload from "./FileDownload";

import styles from "../styles/home.module.css";
function About() {
  return (
    <div className={styles.colorContainer}>
      <Header />
      <div className={styles.body}>
        <h1 className={styles.title}> About SafeSight </h1>
        <h2 className={styles.subtitle}>
          {" "}
          <b> The Problem</b>
        </h2>
        <h4 className={styles.bodytext}>
          {" "}
          Annually, 2.78 million workers die due to work-related accidents and
          diseases, with 374 million more facing non-fatal incidents. That's
          7,500 daily deaths, surpassing those from road accidents, war, and
          HIV/AIDS combined.
          <p />
          When workplace injuries do happen, many of them go unreported and
          workers are not compensated due to workers fearing workplaces or
          issues with the administrative logistics of filling worker comp
          claims.
        </h4>
        <h2 className={styles.subtitle}>
          {" "}
          <b> Our Solution</b>
        </h2>
        <h4 className={styles.bodytext}>
          {" "}
          SafeSight's AI-based technology addresses workplace injury through a
          combination of real-time monitoring, timely incident detection, and
          automated generation of accurate injury reports, ethically protecting
          human safety and efficiency.
          <p />
          Our product vertically integrates and automates all parts of worker
          compensation, which can be summarized in 3 S’s.
          <p />
          STOP: Forget waiting for someone to notice the danger. SafeSight
          reviews security footage in real-time to instantly flag potential
          risks and threats to workplace environments. If spotted, SafeSight
          instantly alerts relevant personnel, ensuring prompt preventative
          action is taken.
          <p />
          SIGHT: In the unfortunate circumstance that a workplace accident
          occurs, SafeSight is the first to know. Our computer vision solution
          not only detects the incident, but also ensures that workers involved
          get the necessary help and treatment from the right people, whether
          that be medical staff, security, or management, ASAP.
          <p />
          SETTLE: Post-event, SafeSight helps you process and understand what
          happened. Our systems generate a detailed report based on footage,
          capturing essential data that aids in both addressing current
          incidents and preventing future ones.
        </h4>
        <h2 className={styles.subtitle}>
          {" "}
          <b> Our Mission </b>
        </h2>
        <h4 className={styles.bodytext}>
          {" "}
          Our mission is to empower businesses and public spaces with real-time
          insights, enabling immediate action to ensure the safety of every
          individual. We believe in proactive prevention, ensuring that
          potential hazards are not just observed but acted upon.
        </h4>
      </div>
      <Footer />
    </div>
  );
}

export default About;
